<template>
  <div class="login" @keydown.enter="login()">

    <div class="login-box">
      <h1>Login</h1>
      <el-input v-model="username" placeholder="用户名"></el-input>
      <el-input type="password" v-model="password" placeholder="密码"></el-input>
      <el-button type="primary" @click="login">登陆</el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data(){
    return{
      username: '',
      password: ''
    }
  },
  methods:{
    login(){
      axios.post('login',{
        username: this.username,
        password: this.password,
      }).then(result => {
        localStorage.setItem("id", result.data.data.id)
        localStorage.setItem("username", result.data.data.username)
        localStorage.setItem("token", result.data.data.api_token)
        localStorage.setItem("role", result.data.data.role)

        this.$root.id = localStorage.getItem('id')??0;
        this.$root.role = localStorage.getItem('role')??'';
        this.$root.username = localStorage.getItem('username')??'';

        this.$router.push({name: "Index"})
      },error=>{
        this.$message({type: 'error', message: error.response.data.message,offset:40});
      });
    }
  }
}
</script>

<style scoped>

/*label{*/
/*  display: block;*/
/*}*/
.login{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box{
  padding: 30px;
  box-shadow: 0 10px 20px 2px #ccc;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}
.login-box *{
  margin: 10px 0;
}

</style>
