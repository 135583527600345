<template>
  <div class="tables">
    <div class="buttons">

      <div v-if="modal" class="modal" @click.self="modal=false">
        <div class="modal-box">
          <h3 style="text-align: center;margin-bottom: 20px;">新增用户</h3>
        <el-input v-model="username" placeholder="用户名" style="margin-bottom: 10px"></el-input>
        <el-input v-model="password" placeholder="密码" style="margin-bottom: 10px"></el-input>
        <el-select v-model="role" placeholder="角色" style="margin-bottom: 10px">
          <el-option
              v-for="item in ['Employee','Administrator']"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="modal = false">取消</el-button>
          <el-button type="primary" size="mini" @click="modal = false;addUser()">创建</el-button>
        </div>
        </div>
      </div>
      <el-button type="primary" @click="modal = true">新增用户</el-button>
    </div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="id" label="id" width="160"></el-table-column>
      <el-table-column prop="username" label="用户名" width="320"></el-table-column>
      <el-table-column prop="role" label="角色" width="220"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button :class="hasAuth(scope.row)?'is-disabled':''" :disabled="hasAuth(scope.row)" @click="open(scope.row)" type="text" size="small">修改密码</el-button>
          <el-button v-if="scope.row.role==='Employee'" :class="this.$root.role!=='SuperAdministrator'?'is-disabled':''" :disabled="this.$root.role!=='SuperAdministrator'" type="text" size="small" @click="changeRole(scope.row,'Administrator')">提升为管理员</el-button>
          <el-button v-if="scope.row.role==='Administrator'" :class="this.$root.role!=='SuperAdministrator'?'is-disabled':''" :disabled="this.$root.role!=='SuperAdministrator'" type="text" size="small" @click="changeRole(scope.row,'Employee')">降级为用户</el-button>
          <el-popconfirm v-if="scope.row.role!=='SuperAdministrator'" title="确定要删除该账号吗？" @confirm="deleteUser(scope.row)" confirm-button-text="删除" cancel-button-text="取消">
            <template #reference>
              <el-button  :class="hasAuth(scope.row)?'is-disabled':''" :disabled="hasAuth(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserManage",
  data() {
    return {
      tableData: [],
      username: '',
      password: '',
      role: '',
      modal: false
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    getList(){
      axios.post('userlist').then(result => {
        this.tableData = result.data
      });
    },
    con(v){
      console.log(v)
    },
    hasAuth(row){
      if (this.$root.role==='SuperAdministrator'){
        return false;
      }
      if (this.$root.role==='Administrator'&&row.id==this.$root.id){
        return false;
      }
      if (this.$root.role==='Administrator'&&row.role==='Employee'){
        return false;
      }
      return true;
    },
    open(data) {
      this.$prompt('请输入新密码', '提示', {
        confirmButtonText: '确定修改',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        console.log(value,data)
        // this.$message({
        //   type: 'success',
        //   message: '你的邮箱是: ' + value
        // });

        axios.post('changePassword/'+ data.id,{password: value}).then(result => {
          this.getList()
          this.$message({type: 'success', message: result.data.message, offset:40});
          // location.reload()
        },error=>{
          this.$message({type: 'error', message: error.response.data.message, offset:40});
        });
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '取消输入'
        // });
      });
    },
    addUser(){
      if (this.username===''){
        this.$message({type: 'info', message: '请输入用户名后创建',offset:40});
      }else if (this.password===''){
        this.$message({type: 'success', message: '请输入密码后创建',offset:40});
      }
      axios.post('createUser',{username:this.username,password:this.password,role:this.role}).then(result => {
        this.getList()
        this.$message({type: 'success', message: result.data.message,offset:40});
        location.reload()
      },error=>{
        this.$message({type: 'error', message: error.response.data.message,offset:40});
      });
    },
    deleteUser(data){
      axios.post('removeUser/'+data.id).then(result => {
        this.getList()
        this.$message({type: 'success', message: result.data.message, offset:40});
      },error=>{
        this.$message({type: 'error', message: error.response.data.message, offset:40});
      });
    },
    changeRole(data,role){
      axios.post('changeRole/'+ data.id,{role: role}).then(result => {
        this.getList()
        this.$message({type: 'success', message: result.data.message, offset:40});
        // location.reload()
      },error=>{
        this.$message({type: 'error', message: error.response.data.message, offset:40});
      });
    }
  }
}
</script>

<style scoped>
.tables{
  width: 80%;
  min-width: 960px;
  margin: 0 auto;
}

.modal{
  z-index: 999;
  width: 100vw;
  height: 120vh;
  left: 0;
  padding: 0;
  position: fixed;
  background: rgba(0,0,0,0.5);
  margin-top: -20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-box{
  width: 300px;
  padding: 30px;
  border-radius: 5px;
  background: white;

}

.modal-box>*{
  display: block;
}
</style>
