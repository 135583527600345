import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios"
import installElementPlus from './plugins/element'


const app = createApp(App)
installElementPlus(app)
app.use(router).mount('#app')




axios.defaults.baseURL = "/api/api"
axios.defaults.baseURL = "https://cert-manager.zhonghui.vip/api/api"
// axios.defaults.baseURL = "http://localhost:8000/api"
axios.interceptors.request.use(request => {
    // request.headers.Accept = "application/json"

    let token = localStorage.getItem("token");
    if (token != null) {
        request.headers.Authorization = "Bearer " + token
    }

    return request;
});


// axios.interceptors.response.use((result) => {return result;},
//     error => {
//         if (error.response){
//             alert(error.response.data.message);
//         }else {
//             alert('Unknown Error!')
//         }
//     }
// );
