import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import UserManage from '../views/UserManage.vue'
import CertificateManagement from '../views/CertificateManagement.vue'
import ExaminationManagement from '../views/ExaminationManagement.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/UserManage',
    name: 'UserManage',
    component: UserManage
  },
  {
    path: '/CertificateManager',
    name: 'CertificateManagement',
    component: CertificateManagement
  },
  {
    path: '/ExaminationManagement',
    name: 'ExaminationManagement',
    component: ExaminationManagement
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
