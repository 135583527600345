<template>
  <div class="tables">
    <div class="buttons">

      <el-button @click="root()"  type="primary">创建根分类</el-button>

    </div>
    <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="id"
          label="序号"
          sortable
          width="100">
      </el-table-column>
      <el-table-column
          prop="exam_type"
          label="分类名称"
          sortable
          width="300">
      </el-table-column>
      <el-table-column
          label="操作">
        <template #default="scope">
          <el-button @click="open(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="sub(scope.row)" v-if="!scope.row.is_children" type="text" size="small">创建子分类</el-button>
          <el-popconfirm v-if="scope.row.role!=='SuperAdministrator'" title="确定要删除该分类吗？" @confirm="deleteExam(scope.row)" confirm-button-text="删除" cancel-button-text="取消">
            <template #reference>
              <el-button   type="text" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserManage",
  data() {
    return {
      tableData: [],
      allTableData: [],
      username: '',
      password: '',
      role: '',
      fileList:'',
      currentPage4: 4,
      pageSize: 10,
      search: ''
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    root(){
      this.$prompt('请输入子分类名称', '提示', {
        confirmButtonText: '确定创建',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        console.log(value)

        axios.post('examCreate',{exam_type: value,superior: 0}).then(result => {
          this.getList()
          this.$message({type: 'success', message: result.data.message, offset:40});
        },error=>{
          this.$message({type: 'error', message: error.response.data.message, offset:40});
        });
      }).catch(() => {
      });
    },
    sub(data){
      this.$prompt('请输入子分类名称', '提示', {
        confirmButtonText: '确定创建',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        axios.post('examCreate',{exam_type: value,superior: data.id}).then(result => {
          this.getList()
          this.$message({type: 'success', message: result.data.message, offset:40});
        },error=>{
          this.$message({type: 'error', message: error.response.data.message, offset:40});
        });
      }).catch(() => {
      });
    },
    open(data) {
      this.$prompt('请输入新名称', '提示', {
        confirmButtonText: '确定修改',
        cancelButtonText: '取消',
        inputValue: data.exam_type
      }).then(({ value }) => {
        if (data.exam_type===value){
          this.$message({type: 'info', message: "未修改", offset:40});
          return;
        }
        axios.post('examEdit/'+ data.id,{exam_type: value}).then(result => {
          this.getList()
          this.$message({type: 'success', message: result.data.message, offset:40});
        },error=>{
          this.$message({type: 'error', message: error.response.data.message, offset:40});
        });
      }).catch(() => {
      });
    },
    deleteExam(row){
       console.log(row)
      axios.post('examRemove/'+ row.id).then(result => {
        this.getList()
        this.$message({type: 'success', message: result.data.message, offset:40});
      },error=>{
        this.$message({type: 'error', message: error.response.data.message, offset:40});
      });
    },
    getList() {
      axios.post('examList').then(result => {
        this.tableData = result.data
      });
    }
  }
}
</script>

<style scoped>
.tables {
  width: 80%;
  min-width: 960px;
  margin: 0 auto;
}
</style>
