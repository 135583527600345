<template>
  <div>
    <h1 v-if="$root.username">你好, {{$root.username}}。</h1>
    <h1 v-if="!$root.username">证书管理后台。</h1>
  </div>
</template>

<script>
export default {
  name: "Index"
}
</script>

<style scoped>
  div{
    line-height: 80vh;
    text-align: center;
  }
</style>
