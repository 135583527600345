<template>
  <div id="nav">
    <router-link to="/">首页</router-link>
    <router-link to="/Login" v-if="!$root.id">登陆</router-link>
    <router-link to="/UserManage" v-if="$root.role==='Administrator'||$root.role==='SuperAdministrator'">用户管理</router-link>
    <router-link to="/ExaminationManagement" v-if="$root.id">分类管理</router-link>
    <router-link to="/CertificateManager" v-if="$root.id">证书管理</router-link>
    <a v-if="$root.id" @click="logout()">登出</a>
  </div>
  <div id="main">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{
      id:0,
      role:'',
      username:''
    }
  },
  mounted(){
    this.id = localStorage.getItem('id')??0;
    this.role = localStorage.getItem('role')??'';
    this.username = localStorage.getItem('username')??'';
  },
  methods:{
    logout(){
      localStorage.clear();
      this.$root.id = localStorage.getItem('id')??0;
      this.$root.role = localStorage.getItem('role')??'';
      this.$root.username = localStorage.getItem('username')??'';
      this.$root.$router.push({name: "Index"})
    }
  }
}
</script>

<style>
*{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

#nav {
  padding: 30px;
  text-align: center;
  background: rgba(212, 212, 212, 0.7);
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  margin: 10px;
  padding: 10px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
#nav a:hover {
  color: rgba(44, 62, 80, 0.8);
}
#nav a.router-link-exact-active {
  color: #42b983;
}

#main{
  margin: 10px;
  overflow: hidden;
}

.buttons{
  margin-bottom: 10px;
}

</style>
