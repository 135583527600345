<template>
  <div class="tables">
    <div class="buttons">
      <div v-if="modal" class="modal" @click.self="modal=false">
        <div class="modal-box">
          <h3 style="text-align: center;margin-bottom: 10px;margin-top: -10px;">导入证书</h3>
          <el-select v-model="exam" placeholder="请选择考试类型" style="margin-bottom: 10px;">
            <el-option
                v-for="item of exams"
                :key="item.id"
                :label="item.exam_type"
                :value="item.id">
            </el-option>
          </el-select>

          <el-select v-if="exam" v-model="cert" placeholder="请选择考试类型" style="margin-bottom: 15px;">
            <el-option
                v-for="item of certs"
                :key="item.id"
                :label="item.exam_type"
                :value="item.id">
            </el-option>
          </el-select>

          <input @change="changeFile" type="file" placeholder="选择文件" style="margin-bottom: 10px">
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="modal = false">取消</el-button>
            <el-button type="primary" size="mini" @click="modal = false;add()">导入</el-button>
          </div>
        </div>
      </div>
      <el-button type="primary" @click="modal = true;">导入证书</el-button>
      <el-link icon="el-icon-paperclip" style="margin-left: 20px;" :underline="false" href="https://cert-manager.zhonghui.vip/download/%E4%B8%8A%E4%BC%A0%E6%A8%A1%E7%89%88.xlsx">下载模版</el-link>
    </div>
    <el-table
        :data="tableData"
        stripe style="width: 100%">
      <el-table-column prop="exam_type" label="考试类型" width="150"></el-table-column>
      <el-table-column prop="c_type" label="证书类型" width="120"></el-table-column>
      <el-table-column prop="username" label="姓名" width="80"></el-table-column>
      <el-table-column prop="c_id" label="证书编号" width="180"></el-table-column>
      <el-table-column prop="u_id" label="身份证号" width="180"></el-table-column>
      <el-table-column prop="c_datetime" label="发证日期" width="100"></el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="100"></el-table-column>
      <el-table-column width="200">
        <template #header>
          <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"/>
        </template>
        <template #default="scope">
          <el-popconfirm v-if="scope.row.role!=='SuperAdministrator'" title="确定要删除此证书吗？"
                         @confirm="deleteCert(scope.row)" confirm-button-text="删除" cancel-button-text="取消">
            <template #reference>
              <el-button type="text" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex;justify-content:center;margin-top: 10px;">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="20"
          layout="prev, pager, next"
          :page-count="pageCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import xlsx from 'xlsx'

export default {
  name: "UserManage",
  data() {
    return {
      tableData: [],
      username: '',
      password: '',
      role: '',
      fileList: '',
      pageCount: 1,
      page: 1,
      search: '',
      exams: [],
      exam: '',
      certs: [],
      cert: '',
      modal: false
    }
  },
  mounted() {
    this.getList();
    this.getExam();
  },
  watch: {
    search() {
      this.getList();
    },
    exam(val) {
      for (let i of this.exams) {
        if (i.id === val) {
          this.certs = i.children
        }
      }
      this.cert = '';
    },
    cert() {
      // console.log(val)
    }
  },
  methods: {
    getExam() {
      axios.post('examList').then(result => {
        this.exams = result.data
        console.log(this.exams)
      });
    },
    handleCurrentChange(val) {
      this.tableData = [];
      this.page = val;
      this.getList();
    },
    changeFile(e) {
      var files = e.target.files;
      var fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          var data = ev.target.result, workbook = xlsx.read(data, {type: 'binary'}),
              persons = [];
        } catch (e) {
          console.log('文件类型不正确');
          return;
        }
        var fromTo = '';
        console.log(fromTo);
        for (var sheet in workbook.Sheets) {
          // eslint-disable-next-line no-prototype-builtins
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            fromTo = workbook.Sheets[sheet]['!ref'];
            persons = persons.concat(xlsx.utils.sheet_to_json(workbook.Sheets[sheet]));
            break;
          }
        }

        this.fileList = persons;
      };
      fileReader.readAsBinaryString(files[0]);
    },
    add() {
      let excel = [];
      for (let data of this.fileList) {
        excel.push({
          exam_type: data.考试类型,
          c_type: data.证书类型,
          username: data.姓名,
          c_id: data.证书编号,
          u_id: data.身份证号,
          c_datetime: data.发证日期,
          remarks: data.备注,
        });
      }
      axios.post('create', {
        data: excel,
        exam: this.exam,
        cert: this.cert
      }).then(result => {
        this.getList()
        this.$message({type: 'success', message: result.data.message});
      }, error => {
        this.$message({type: 'error', message: error.response.data.message});
      });
    },
    getList() {
      this.tableData = [];
      axios.post('search' + (this.search === '' ? '' : '/') + this.search + '?page=' + this.page).then(result => {
        this.tableData = result.data.data;
        this.pageCount = result.data.last_page;
      });
    },
    deleteCert(data) {
      axios.post('removeCertificate/' + data.id).then(result => {
        this.getList()
        this.$message({type: 'success', message: result.data.message});
      }, error => {
        this.$message({type: 'error', message: error.response.data.message});
      });
    }
  }
}
</script>

<style scoped>
.tables {
  width: 80%;
  min-width: 960px;
  margin: 0 auto;
}

.modal{
  z-index: 999;
  width: 100vw;
  height: 120vh;
  left: 0;
  padding: 0;
  position: fixed;
  background: rgba(0,0,0,0.5);
  margin-top: -20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-box{
  width: 300px;
  padding: 30px;
  border-radius: 5px;
  background: white;

}

.modal-box>*{
  display: block;
}
.el-message{
  top: 80px !important;
}
</style>
